import { sha256 } from 'js-sha256'
import { Consts } from '@/utils/consts'

export function request({ method, api, data = {} }) {
  return new Promise((resolve, reject) => {
    let url = Consts.HomApiUrl;
    let options = { 
      // mode: 'no-cors',    // no-cors, cors, *same-origin
      // credentials: 'omit', // include, same-origin, *omit
      // redirect: 'follow', // manual, *follow, error
      // headers: { "Accept": "application/json" }
    };
    if (method == 'GET') {
      url += `${api}/?${data}`;
      options.method = 'GET';
      // options.headers = {'Content-Type': 'text/plain'};
    } else {
      const timestamp = Date.now();
      const digest = sha256(Consts.HomApiKey + timestamp + Consts.HomApiSecret);
      url += `${api}/api=${Consts.HomApiKey}&apiSignature=${digest}`;
      options.method = 'POST',
      options.headers = {'Content-Type': 'application/json'},
      options.body = JSON.stringify(data)
    }
    
    url += `&app_version=` + Consts.HomApiVersion;
    fetch(url, options)
    // .then(res => res.text()) 
    .then(res => res.json()) 
    .then(data => {
      // console.log('data:', data);
      if (data.success == 'Y') {
        return resolve(data);
      } else {
        return reject('data return failed');
      }
    })
    .catch(err => {
      console.log('err:', err);
      return reject(err);
    })
  })
}
