const Menu = {
  en: ['Buy', 'Featrued', 'About'],
  cn: ['买房', '精选', '关于'],
};
const Title = {
  en: ['Multiple Listing Service', 'Daily Breakdown', 'About'],
  cn: ['MLS房源', '每日精选', '关于'],
};
const PriceAssess = {
  en: ['Free Assessment',
    'I would appreciate more information about',
  ],
  cn: ['免费房产评估',
    '我希望了解更多信息关于房产',
  ]
};
const PropertyList = {
  en: ['Bedrooms', 'Bathrooms', 'Sqft.', 'Built', 'Listing by'],
  cn: ['睡房', '卫浴', '平方英尺', '建造于', '挂牌公司'],
};
const PropertyDetail = {
  en: ['Detail', 'MLS', 'Address', 'City', 'Title to Land', 'Lot Size(Sqft.)', 'Built Year', 'Last Year Taxes', 'Open House', 'Listing Date', 'Listing Brokerage'],
  cn: ['详细信息', 'MLS', '地址', '城市', '土地所有权', '土地面积(平方英尺)', '建造年份', '上年地税', '开放看房', '挂牌时间', '挂牌地产公司'],
};
const PropertyDesc = {
  en: ['Description'],
  cn: ['房产介绍'],
};
const AgentInfo = {
  en: ['Buyer Agent --- We Can Help'],
  cn: ['买方经纪人 --- 我们为您服务',
  ],
};
const MortgageInfo = {
  en: ['Mortgage estimate', 'Down-pay%', 'Apr%', 'Amortized yr', 'Frequency', 'Price', 'Down payment', 'Principal amount', 'Recurring payment',
    "Disclaimer: This calculator is for demonstration purposes only. We don't guarantee that all calculations are accurate. Always consult a professional financial advisor before making personal financial decisions."
  ],
  cn: ['贷款估算', '首付率 %', '年利率 %', '贷款期 年', '还款周期', '价格', '首付', '本金', '周期还款',
    '免责声明：此计算器仅用于演示目的。 我们不保证所有计算都是准确的。 在做出个人财务决定之前，请务必咨询专业财务顾问。'
  ],
};
const TravelInfo = {
  en: ['Travel index', 
    "Disclaimer: The higher the score, the more convenient the corresponding travel way."
  ],
  cn: ['出行指数', 
    '免责声明：分值越高，对应的交通方式越便利'
  ],
};
const SchoolInfo = {
  en: ['School catchment', 'Elementary', 'Secondary', 'km distance',
    "Disclaimer: This school catchment data is based in whole or in part on data generated by the Metroplitan cities & school districts and school ranking is from Fraser Institute,  which HOMAPP.COM assumes no resposibility for its accuracy."
  ],
  cn: ['校区信息', '小学', '中学', '公里距离',
    '免责声明：以上校区信息，根据大都会的各市政府或教育部门信息由系统自动匹配完成，学校排名信息来自Fraser Institute。所有数据仅提供参考，HOMAPP.COM不为其准确性负责，对相应的二手房源信息不作任何入升学承诺。'
  ],
};
const MapInfo = {
  en: ['Map view','Drag the human icon into map for StreetView mode, ESC to exit StreetView mode', 'Road Map', 'Street View'],
  cn: ['地图视角', '将人形图标拖入地图切换到街景模式，ESC退出街景模式', '道路图', '街景图'],
};
const SimilarListing = {
  en: ['Similar listing'],
  cn: ['相似房源'],
};
const AboutUs = {
  en: [
    'About us',
    '.HOMAPP.COM website is operated by ', 
    ', who is licensed agent of ',
    '',
    'FairyCOW Software Inc. provide technology service only, who is qualified DDF® Technology Provider by CREA',
    'If you have any technical questions, please contact us by e-mail: info@homapp.com',
    "Free property evaluation",
    'Submit'
  ],
  cn: [
    '关于我们',
    '.HOMAPP.COM网站由', 
    '运营，他是',
    '的持牌经纪人',
    'FairyCOW Software Inc.仅提供技术服， 它是CREA的合格DDF®数据提供商',
    '如果您有任何技术问题，请通过邮件info@homapp.com联系我们',
    '免费房产估价',
    '提交'
  ],
};


export const Lang = {
  Menu,
  Title,
  PriceAssess,
  PropertyList,
  PropertyDetail,
  PropertyDesc,
  AgentInfo,
  MortgageInfo,
  TravelInfo,
  SchoolInfo,
  MapInfo,
  SimilarListing,
  AboutUs,
}