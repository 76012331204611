const HmcApiUrl = process.env.VUE_APP_HMC_URL + '/api';       // API接口地址
const HmcApiUid = process.env.VUE_APP_HMC_API_UID;
const HmcApiToken = process.env.VUE_APP_HMC_API_TOKEN;
const HmcApiSecret = process.env.VUE_APP_HMC_API_SECRET;

const HomApiKey = process.env.VUE_APP_HOM_API_KEY;
const HomApiSecret = process.env.VUE_APP_HOM_API_SECRET;
const HomApiUrl = process.env.VUE_APP_HOM_URL;
const HomApiVersion = '3.0.0w';
const PageSize = 16;
const IosApp = 'https://apps.apple.com/app/id879209391';
const AndroidApp = 'https://play.google.com/store/apps/details?id=ca.city365.homapp';
const DwellingOptions = [
  {
    value: 'house',
    label: 'Detached House',
  },
  {
    value: 'duplex',
    label: 'Duplex House',
  },
  {
    value: 'townhouse',
    label: 'Townhouse',
  },
  {
    value: 'apartment',
    label: 'Apartment/Condon',
  }
];
const PriceRange = [
  {
    value: 1000,
    label: 'Over $10,000,000',
  },
  {
    value: 600,
    label: '$6,000,000-$9,999,999',
  },
  {
    value: 500,
    label: '$5,000,000-$5,999,999',
  },
  {
    value: 400,
    label: '$4,000,000-$4,999,999',
  },
  {
    value: 300,
    label: '$3,000,000-$3,999,999',
  },
  {
    value: 200,
    label: '$2,000,000-$2,999,999',
  },
  {
    value: 100,
    label: '$1,000,000-$1,999,999',
  },
  {
    value: 50,
    label: '$500,000-$999,999',
  },
  {
    value: 10,
    label: '$100,000-$499,999',
  }
];
const LotSizeRange = [
  {
    value: 100,
    label: 'Over 435,600',
  },
  {
    value: 600,
    label: '217,800-435,599',
  },
  {
    value: 20,
    label: '87120-217,799',
  },
  {
    value: 10,
    label: '43,560-87,199',
  },
  {
    value: 9,
    label: '10,000-43,559',
  },
  {
    value: 8,
    label: '8,000-9,999',
  },
  {
    value: 6,
    label: '6,000-7,999',
  },
  {
    value: 4,
    label: '4,000-5,999',
  },
  {
    value: 2,
    label: '2,000-3,999',
  },
  {
    value: 1,
    label: '0-1,999',
  }
];
const BreakdownOptions = [
  {
    value: 1,
    label: 'Open House',
  },
  {
    value: 2,
    label: 'New Listing',
  },
  {
    value: 3,
    label: 'Price Change',
  },
  // {
  //   value: 4,
  //   label: 'Just Sold',
  // }
];
const SortbyOptions = [
  {
    value: 1,
    label: 'Default',
  },
  {
    value: 2,
    label: 'Price(low to high)',
  },
  {
    value: 3,
    label: 'Price(high to low)',
  },
];
const AmortizationPeriod = [5, 10, 15, 20, 25, 30];
const PaymentFrequency = ['Monthly', 'Semi-monthly', 'Bi-weekly'];
const TermOfUseAgreement = 
'a.Text that states CREA is the owner of the REALTOR® and MLS® trademarks: “REALTOR®, REALTORS®, and the REALTOR® logo are certification marks that are owned by REALTOR® Canada Inc. and licensed exclusively to The Canadian Real Estate Association (CREA). These certification marks identify real estate professionals who are members of CREA and who must abide by CREA’s ByLaws, Rules, and the REALTOR® Code. The MLS® trademark and the MLS® logo are owned by CREA and identify the quality of services provided by real estate professionals who are members of CREA.”;'
+ 'b.Text that disclaims liability of CREA as a result of providing the data feed: “The information contained on this site is based in whole or in part on information that is provided by members of The Canadian Real Estate Association (CREA), who are responsible for its accuracy. CREA reproduces and distributes this information as a service for its members and assumes no responsibility for its accuracy”;'
+ 'c. Text that states the website is operated by a brokerage or salesperson who is a member of The Canadian Real Estate Association; and '
+ 'd.Copyright ownership text: “The listing content on this website is protected by copyright and other laws, and is intended solely for the private, non-commercial use by individuals. Any other reproduction, distribution or use of the content, in whole or in part, is specifically forbidden. The prohibited uses include commercial use, “screen scraping”, “database scraping”, and any other activity intended to collect, store, reorganize or manipulate data on the pages produced by or displayed on this website.”'


export const Consts = {
  HmcApiUrl,
  HmcApiUid,
  HmcApiToken,
  HmcApiSecret,
  HomApiKey,
  HomApiSecret,
  HomApiUrl,
  HomApiVersion,
  PageSize,
  IosApp,
  AndroidApp,
  DwellingOptions,
  PriceRange,
  LotSizeRange,
  BreakdownOptions,
  SortbyOptions,
  AmortizationPeriod,
  PaymentFrequency,
  TermOfUseAgreement,
} 
