<template>
  <div class="top-nav">
    <el-row :gutter="20">
      <el-col :xs="{span:6, push:1}" :sm="{span:6, push:1}" :md="{span:6, push:1}" :lg="{span:4, push:1}" :xl="{span:4, push:1}">
        <div @click="handleClick">
          <el-image class="top-logo-image" fit="fill" :src="logoUrl" alt="homapp_logo" />
          <span class="top-logo-title">&nbsp;HOMAPP</span>
        </div>
      </el-col>
      <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10">
        <el-image style="width:160px; height:40px; padding-top: 8px" :fit="'fill'" :src="metroUrl" alt="metro_logo" />
        <span class="top-title">&nbsp;{{metroName}}</span>
      </el-col>
      <el-col :xs="{span:4, push:3}" :sm="{span:4, push:3}" :md="{span:4, push:3}" :lg="10" :xl="10">
        <div  class="top-menu">
          <div v-if="isLargeScreen">
            <el-menu mode="horizontal" :default-active="activeIndex" @select="handleSelect">
              <el-menu-item index="1"><span class="top-menu-item">{{menu[0]}}</span></el-menu-item>
              <el-menu-item index="2"><span class="top-menu-item">{{menu[1]}}</span></el-menu-item>
              <el-menu-item index="3"><span class="top-menu-item">{{menu[2]}}</span></el-menu-item>
              <el-menu-item index="6">
                <el-switch 
                  v-model="switchValue" 
                  inline-prompt
                  active-text="EN"
                  inactive-text="中" 
                  size="large" 
                  style="--el-switch-on-color: #3D80B4; --el-switch-off-color: #ff4949; padding-top: 8px;"
                />
              </el-menu-item>
            </el-menu>
          </div>
          <div v-else>
            <el-button @click="handleOpen"><el-icon><Fold /></el-icon></el-button>
            <el-drawer v-model="isOpenDrawer" :show-close="false" :append-to-body="true" size="40%" :before-close="handleClose">
              <el-menu mode="vertical" :default-active="activeIndex" @select="handleSelect">
                <el-menu-item index="1"><span class="top-menu-item">{{menu[0]}}</span></el-menu-item>
                <el-menu-item index="2"><span class="top-menu-item">{{menu[1]}}</span></el-menu-item>
                <el-menu-item index="3"><span class="top-menu-item">{{menu[2]}}</span></el-menu-item>
                <el-menu-item index="6">
                  <el-switch 
                    v-model="switchValue" 
                    inline-prompt
                    active-text="EN"
                    inactive-text="中" 
                    size="large" 
                    style="--el-switch-on-color: #3D80B4; --el-switch-off-color: #ff4949; padding-top: 8px;"
                  />
                </el-menu-item>
              </el-menu>
            </el-drawer>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import { Fold } from '@element-plus/icons-vue'
import { Lang } from '@/utils/language'
export default {
  name: 'TopNav',
  components: { Fold },
  props: {
    metro: {
      required: true,
      type: String
    },
    metroLogo: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      isLargeScreen: this.$store.state.largeScreen,
      isOpenDrawer: false,
      activeIndex: '1',
      switchValue: true,
      logoUrl: require('@/assets/logo.png'),
      metroUrl: this.metroLogo,
      metroName: this.metro,
    }
  },
  computed: {
    menu() {
      if (this.switchValue) {
        this.$store.commit('changeLang', 'en')
        return Lang.Menu.en;
      } else {
        this.$store.commit('changeLang', 'cn')
        return Lang.Menu.cn;
      };
    }
  },
  watch: {
    metro(newVal) {
      this.metroName = newVal
    },
    metroLogo(newVal) {
      this.metroUrl = newVal
    },
  },
  methods: {
    handleOpen(){
      this.isOpenDrawer = true;
    },
    handleClose(){
      this.isOpenDrawer = false;
    },
    handleClick() {
      window.open('https://www.homapp.com', '_blank');
    },
    handleSelect(idx) {
      switch(idx) {
        case '1':
          this.$router.push({ 
            name: 'home',
          });
          break;
        case '2':
          this.$router.push({ 
            name: 'featured',
          });
          break;
        case '3':
          this.$router.push({ 
            name: 'about',
          });
          break;
        default:
      }
      this.handleClose();
    },
  }
}
</script>


<style lang="scss">
@import '@/assets/variables.scss';
.top-nav {
  z-index: 1;
  height: $top-height;
  border-bottom: 1px solid rgba(144, 147, 153, 0.2);
  background-color: white;
}
.top-logo-image {
  padding-top: 10px;
  width: 50px; 
  height: 50px;
  vertical-align: middle;
  cursor: pointer;
}
@media screen and (min-width: 1201px) {
.top-logo-title {
  font-size: 2em;
  font-weight: 700;
  color: $theme-color;
  vertical-align: middle;
  cursor: pointer;
}
.top-title {
  font-size: 1.5em;
  font-weight: 700;
}
}
@media screen and (max-width: 1200px) {
.top-logo-title {
  display: none;
}
.top-title {
  display: none;
}
}
.top-menu {
  padding-top: 10px;
  text-align: right;
}
.top-menu-item {
  font-size: 1.3em;
}
// .top-nav .el-image {
//   text-align: left;
// }
// .top-nav .el-switch {
//   text-align: right;
// }
</style>
