import { createApp, onMounted } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

/*
import firebase from 'firebase/app';
import 'firebase/analytics';
const firebaseConfig = {
  apiKey: 'YOUR_API_KEY',
  authDomain: 'YOUR_AUTH_DOMAIN',
  projectId: 'YOUR_PROJECT_ID',
  storageBucket: 'YOUR_STORAGE_BUCKET',
  messagingSenderId: 'YOUR_MESSAGING_SENDER_ID',
  appId: 'YOUR_APP_ID',
  measurementId: 'YOUR_MEASUREMENT_ID'  // 可选，用于 Firebase Analytics
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
*/
const app = createApp(App)
.use(store)
.use(router)
.use(ElementPlus)
.mount('#app')

// 使用 onMounted 钩子来监听窗口大小变化
// onMounted(() => {
//   window.onresize = function() {
//     app.config.globalProperties.$store.commit('changeScreen');
//   };
// });