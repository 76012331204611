<template>
  <div v-if="tag">
    <router-view />
  </div>
  <div v-else>
    <top-nav :metro="metroName" :metroLogo="metroLogoUrl" />
    <router-view v-slot="{ Component }">
      <transition>
        <keep-alive exclude="DetailView">
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
    <bottom-claim :realtor="agentName" :broker="brokerName" :brokerLogo="brokerLogoUrl" />
  </div>
</template>


<script>
// import { Config } from '../public/conf/config'
import { remoteJS } from '@/utils/remoteJS.js'
import { getMetroList } from '@/utils/api'
import TopNav from '@/components/TopNav'
import BottomClaim from '@/components/BottomClaim'
export default {
  components: { TopNav, BottomClaim },
  data() {
    return {
      metroLogoUrl: '',
      metroName: '',
      brokerLogoUrl: '',
      brokerName: '',
      agentName: '',
    }
  },
  created() {
    // 设置CSS变量的值
    document.documentElement.style.setProperty('--width', window.innerWidth + 'px');
    document.documentElement.style.setProperty('--height', window.innerHeight + 'px');

    let metropolitan = 'vancouver';
    if (process.env.NODE_ENV == 'production') {
      const host = window.location.host;
      metropolitan = host.replace('.homapp.com', '');
    }
    remoteJS(metropolitan).then(() => {
      const Config = Configs();
      this.metroLogoUrl = Config.MetroLogo;
      this.metroName = Config.MetroName;
      this.metroPosition = Config.MetroPosition;
      this.brokerLogoUrl = Config.BrokerLogo;
      this.brokerName = Config.BrokerName;
      this.agentName = Config.AgentName.en;

      this.$store.commit('changeMetroPosition', this.metroPosition);
      this.$store.commit('changeMetroName', this.metroName);
      getMetroList(Config.MetroName).then(res => {
        this.$store.commit('changeMetroCity', res.data);
      }).catch(err => {
        console.log('Not get metro city');
      })
    })
  },
  mounted() {
    const pathname = window.location.pathname;
    const regex = /^\/assess\/tag/;
    this.tag = regex.test(pathname);
    if (this.tag) return;

    // User cookie for visited
    window.onload = function () {
      if (localStorage.getItem('hasVisitedHOMAPP') === null) {
        alert('If you click OK, you accept the Terms of Use Agreement. You can review the Agreement at any time on the bottom of the page.');
        localStorage.setItem('hasVisitedHOMAPP', "true");
        window.location.reload();
      }
    };
  }
}
</script>


<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}
:root { // 全局变量
  --width: 0;
  --height: 0;
}
</style>
