import { request } from '@/utils/request'
import requestEx from '@/utils/requestEx'

export function getMetroList(params = {}) {
  let data = `metro_area=${params}`;
  return request({
    method: 'GET',
    api: 'metro_area',
    data: data,
  })
}

export function getMlsRange(metroName, params = {}) {
  let data = `metro_area=${metroName}&top=${params.topLat}&right=${params.topLng}&down=${params.downLat}&left=${params.downLng}`;
  let str = JSON.stringify(params.filters);
  if (str != '{}') {
    str = str.toLowerCase();
    data += str.replaceAll(':', '=').replaceAll(',"','&').replaceAll(',', '')
        .replaceAll('"', '').replace('{', '&').replace('}', '')    
        .replace('dwelling', 'type').replace('bedrooms', 'bed_rooms')/* .replace('/condo', '') */
        .replace('price', 'price_min').replace('-$', '&price_max=').replace('$', '')
        .replace('lotsize', 'lot_size_min').replace('-', '&lot_size_max=');
  }

  return request({
    method: 'GET',
    api: 'property_list',
    data: data,
  })
}

export function getMlsList(metroName, params = {}) {
  let data = `metro_area=${metroName}&from=${params.from}&size=${params.size}`;
  let str = JSON.stringify(params.filters);
  if (str != '{}') {
    str = str.toLowerCase();
    data += str.replaceAll(':', '=').replaceAll(',"','&').replaceAll(',', '')
        .replaceAll('"', '').replace('{', '&').replace('}', '')    
        .replace('dwelling', 'type').replace('bedrooms', 'bed_rooms')/* .replace('/condo', '') */
        .replace('price', 'price_min').replace('-$', '&price_max=').replace('$', '')
        .replace('lotsize', 'lot_size_min').replace('-', '&lot_size_max=');
  }

  return request({
    method: 'GET',
    api: 'property_list',
    data: data,
  })
}

export function getFeaturedList(params = {}) {
  let data = `from=${params.from}&size=${params.size}&metro_area=${params.metro}&city=${params.filters.city}`;
  switch (params.filters.breakdown) {
    case 'Open House':
      data += `&open_house=Y`;
      break;
    case 'New Listing':
      data += `&new_listing_only=Y`;
      break;
    case 'Price Change':
      data += `&price_change_only=Y`;
      break;
    case 'Just Sold':
      data += `&sold_only=Y`;
      break;
  }
  switch (params.filters.sortby) {
    case 'Price(low to high)':
      data += `&sort=price_low`;
      break;
    case 'Price(high to low)':
      data += `&sort=price_high`;
      break;
    default:
      data += `&sort=default`;
  };

  return request({
    method: 'GET',
    api: 'property_list',  // 'featured_list',
    data: data,
  })
}

export function getPropertyDetail(params = {}) {
  switch(params.name) {
    case 'MLS':
    case 'Featured':
      return request({
        method: 'GET',
        api: 'property',
        data: `sysid=${params.sysid}`
      });
      break;
    case 'Sold':
    default:
  }
}

export function updateAssess(params = {}) {
  return requestEx({
    url: 'agent.assess',
    method: 'post',
    data: params
  })
}