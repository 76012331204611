export function remoteJS(metropolitan) {
  return new Promise(function (resolve, reject) {
    const url = `https://storage.googleapis.com/metropolitan.homapp.com/${metropolitan}/configs.js`
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    document.body.appendChild(script);
    script.onerror = reject;
    script.onload = () => {
      resolve();
    }
  })
}