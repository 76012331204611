import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { 
      keepAlive: true
    },
    // component: HomeView,
  	component: () => import('../views/HomeView.vue')
  },
  {
    path: '/featured',
    name: 'featured',
    component: () => import('../views/FeaturedView.vue')
  },
  {
    path: '/detail/:name/:sysid',
    name: 'detail',
    component: () => import('../views/DetailView.vue')
  },  
  {
    path: '/map',
    name: 'map',
    component: () => import('../views/MapView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/assess/tag',
    name: 'assess tag',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/assess/:mls/:message',
    name: 'assess',
    component: () => import('../views/AboutView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
